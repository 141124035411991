import React from 'react';
import { Link } from 'gatsby';
import { categoryPermalink } from '../utils/categories';
import './Categories.css';

export default function({ categories }) {
  if (!categories || !Array.isArray(categories)) {
    return undefined;
  }

  return (
    <ul className="categories">
      {categories.map(category => (
        <li className="categories__category" key={category}>
          <Link
            to={categoryPermalink(category)}
            className="categories__categoryLink"
          >
            {category}
          </Link>
        </li>
      ))}
    </ul>
  );
}
