import React from 'react';
import { Link } from 'gatsby';

import Authors from '../components/Authors';
import Categories from '../components/Categories';
import './PostList.css';

function PostList({ posts }) {
  return (
    <React.Fragment>
      {posts.map(({ node }) => {
        const post = node;
        const title = post.frontmatter.title || post.fields.slug;
        const { categories, authors } = post.frontmatter;
        return (
          <div key={post.fields.slug} className="postList__post">
            <h2 className="postList__postTilte">
              <Link to={post.fields.slug}>{title}</Link>
            </h2>
            {categories && (
              <div>
                <Categories categories={categories} />
              </div>
            )}
            <small>{post.frontmatter.date}</small>
            {authors && (
              <small>
                {' '}
                by <Authors authors={authors} />
              </small>
            )}
            <div
              dangerouslySetInnerHTML={{
                __html: post.fields.teaser || post.excerpt
              }}
            />
            <p>
              <Link to={post.fields.slug}>Read more…</Link>
            </p>
          </div>
        );
      })}
    </React.Fragment>
  );
}

export default PostList;
