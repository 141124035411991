import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import './Footer.css';

export default function() {
  return (
    <StaticQuery
      query={footerQuery}
      render={data => {
        const {
          site: {
            siteMetadata: { twitter }
          }
        } = data;
        return (
          <footer>
            <ul className="footer__list">
              <li className="footer__listItem">
                <a
                  className="footer__link"
                  href="http://status.soundcloud.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Status
                </a>
              </li>
              <li className="footer__listItem">
                <a className="footer__link" href="/docs/api/privacy">
                  Privacy
                </a>
              </li>
              <li className="footer__listItem">
                <a className="footer__link" href="/docs/api/cookies">
                  Cookies
                </a>
              </li>
              <li className="footer__listItem">
                <a
                  className="footer__link"
                  href="https://soundcloud.com/imprint"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Imprint
                </a>
              </li>
              <li className="footer__listItem  m-twitter">
                <a
                  className="footer__link"
                  href={twitter.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  @{twitter.name}
                </a>
              </li>
            </ul>
          </footer>
        );
      }}
    />
  );
}

const footerQuery = graphql`
  query FooterQuery {
    site {
      siteMetadata {
        twitter {
          link
          name
        }
      }
    }
  }
`;
