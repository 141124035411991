import React from 'react';
import { StaticQuery, Link, graphql } from 'gatsby';
import './Header.css';

export default function() {
  return (
    <StaticQuery
      query={headerQuery}
      render={data => {
        const {
          site: {
            siteMetadata: { twitter }
          }
        } = data;
        return (
          <nav className="navigation">
            <Link to="/" className="navigation__item navigation__home" />
            <div className="navigation__links">
              <a
                href="https://developers.soundcloud.com"
                className="navigation__item"
              >
                Developers
              </a>
              <Link
                to="/"
                className="navigation__item navigation__item--active"
              >
                Blog
              </Link>
              <a href={twitter.link} className="navigation__item">
                Twitter
              </a>
              <a 
                href="https://soundcloud.com/you/apps"
                className="navigation__item">
                Your Apps
              </a>
              <a
                href="https://soundcloud.com/jobs"
                className="navigation__item"
              >
                Jobs
              </a>
            </div>
          </nav>
        );
      }}
    />
  );
}

const headerQuery = graphql`
  query HeaderQuery {
    site {
      siteMetadata {
        twitter {
          link
        }
      }
    }
  }
`;
