import React from 'react';
import './CookieBanner.css';

export default class CookieBanner extends React.Component {
  state = {
    visible: false
  };

  componentDidMount() {
    this.setState({
      visible: !hasDismissedBanner()
    });
  }

  dismissBanner = () => {
    this.setState({
      visible: false
    });
    storeDismiss();
  };

  render() {
    if (!this.state.visible) {
      return null;
    }
    return (
      <div className="cookieBanner">
        <div className="cookieBanner__text">
          We use cookies for various purposes including analytics. By using our
          services, you consent to our use of cookies as described in the{' '}
          <a
            href="https://soundcloud.com/pages/cookies"
            target="_blank"
            rel="noopener noreferrer"
          >
            Cookies Policy
          </a>
          .
        </div>
        <div
          role="button"
          title="Dismiss"
          onClick={this.dismissBanner}
          className="cookieBanner__dismiss"
        />
      </div>
    );
  }
}

const BANNER_NAME = 'cookiebanner-2019-20-06';

function localStorageSupported() {
  try {
    return (
      window.localStorage && typeof window.localStorage.getItem === 'function'
    );
  } catch (e) {
    return false;
  }
}

function hasDismissedBanner() {
  if (localStorageSupported() && window.localStorage.getItem(BANNER_NAME)) {
    return true;
  } else if (new RegExp(BANNER_NAME + '=1').test(window.document.cookie)) {
    return true;
  }
  return false;
}

function storeDismiss() {
  if (localStorageSupported()) {
    window.localStorage.setItem(BANNER_NAME, '1');
  } else {
    window.document.cookie = BANNER_NAME + '=1; path=/';
  }
}
