import React from 'react';
import { Link, StaticQuery, graphql } from 'gatsby';
import { Location } from '@reach/router';

import { categoryPermalink } from '../utils/categories';

import './Sidebar.css';

/**
 * Renders a sidebar that currently consists of the list of the blog's categories
 */
function Sidebar({ allMarkdownRemark: { group } }) {
  return (
    <aside>
      <div className="sidebar__title">Categories</div>
      <Location>
        {({ location }) => (
          <ul className="sidebar__categories">
            {group.map(({ fieldValue }) => {
              const route = categoryPermalink(fieldValue);
              const isCurrent = routesEqual(location.pathname, route);
              return (
                <li key={fieldValue} className="sidebar__category">
                  <Link
                    to={route}
                    className={`sidebar__link ${isCurrent ? 'm-current' : ''}`}
                  >
                    {fieldValue}
                  </Link>
                </li>
              );
            })}
          </ul>
        )}
      </Location>
    </aside>
  );
}

export default () => (
  <StaticQuery
    query={graphql`
      query {
        allMarkdownRemark {
          group(field: frontmatter___categories) {
            fieldValue
          }
        }
      }
    `}
    render={Sidebar}
  />
);

function routesEqual(routeA, routeB) {
  return decodeURIComponent(routeA).indexOf(decodeURIComponent(routeB)) !== -1;
}
