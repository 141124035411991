function pagePermalink({ currentPage, basePathName }) {
  const basePath = basePathName ? basePathName : ``;
  const isFirstPage = currentPage - 1 === 0;
  return isFirstPage ? basePathName || '/' : `${basePath}/page/${currentPage}`;
}

function nextPageLink({ currentPage, basePathName }) {
  return pagePermalink({ currentPage: currentPage + 1, basePathName });
}

function previousPageLink({ currentPage, basePathName }) {
  return currentPage - 1 === 1
    ? pagePermalink({ currentPage: 1, basePathName })
    : pagePermalink({ currentPage: currentPage - 1, basePathName });
}

module.exports = {
  pagePermalink,
  nextPageLink,
  previousPageLink
};
