import React from 'react';
import Helmet from 'react-helmet';
import Header from './Header';
import Footer from './Footer';
import favicon from '../assets/favicon.ico';

import './Layout.css';
import CookieBanner from './CookieBanner';

export default function({ className = '', children }) {
  return (
    <React.Fragment>
      <Helmet
        link={[
          { rel: 'shortcut icon', type: 'image/ico', href: `${favicon}` },
        ]}
      />
      <Header />
      <div className={`layout__container ${className}`}>{children}</div>
      <div className="layout__spacing">
        <Footer />
      </div>
      <CookieBanner />
    </React.Fragment>
  );
}
