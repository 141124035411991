import React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/Layout';
import SEO from '../components/seo';
import PostList from '../components/PostList';
import Sidebar from '../components/Sidebar';

import { nextPageLink, previousPageLink } from '../utils/pagination';
import rssLogo from '../rss.svg';

import './blog-posts-pagination.css';

export default class BlogPostsPagination extends React.Component {
  render() {
    const {
      data,
      pageContext: { numPages, currentPage, basePathName, category }
    } = this.props;
    const posts = data.allMarkdownRemark.edges;
    const isLast = numPages === currentPage;
    const isFirst = currentPage === 1;

    return (
      <Layout className="blogPosts">
        <div className="blogPosts__main">
          <div className="blogPosts__head">
            <div className="blogPosts__titleContainer">
              <h1 className="blogPosts__title">Backstage Blog</h1>
              <a href="/blog/blog.rss">
                <img src={rssLogo} className="blogPosts__rss" alt="RSS logo" />
              </a>
            </div>
            {!category && <SEO title="All posts" />}
            {category && (
              <React.Fragment>
                <SEO title={`Posts by category: ${category}`} />
                <p className="blogPosts__category">
                  You're browsing posts of the category{' '}
                  <span className="blogPosts__highlight">{category}</span>
                </p>
              </React.Fragment>
            )}
          </div>

          <PostList posts={posts} />

          <div className="blogPosts__nav">
            {!isFirst && (
              <Link
                to={previousPageLink({ currentPage, basePathName })}
                rel="prev"
                className="blogPosts__navLink blogPosts__prevLink"
              >
                ← Newer
              </Link>
            )}
            {!isLast && (
              <Link
                to={nextPageLink({ currentPage, basePathName })}
                rel="next"
                className="blogPosts__navLink blogPosts__nextLink"
              >
                Older →
              </Link>
            )}
          </div>
        </div>
        <div className="blogPosts__sidebar">
          <Sidebar />
        </div>
      </Layout>
    );
  }
}
