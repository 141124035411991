function categoryPermalink(category = '') {
  return `/category/${normalizeCategory(category)}`;
}

function normalizeCategory(category = '') {
  return category.toLowerCase();
}

module.exports = {
  categoryPermalink
};
