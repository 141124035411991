import React from 'react';

export default function Authors({ authors }) {
  if (authors.length === 1) {
    return Author(authors[0]);
  } else if (authors.length > 1) {
    const lastAuthor = authors.pop();
    return (
      <React.Fragment>
        {CommaSeparatedAuthors(authors)} {'and'} {Author(lastAuthor)}
      </React.Fragment>
    );
  }
  return null;
}

function CommaSeparatedAuthors(authors) {
  const authorsLength = authors.length;
  return authors.reduce((acc, currentAuthor, currentIndex) => {
    acc.push(Author(currentAuthor));
    if (currentIndex < authorsLength - 1) {
      acc.push(', ');
    }
    return acc;
  }, []);
}

function Author({ name, link }) {
  if (link) {
    return (
      <a href={link} target="_blank" rel="noopener noreferrer" key={name}>
        {name}
      </a>
    );
  } else {
    return <span key={name}>{name}</span>;
  }
}
